import React from 'react';
import { Layout, Container } from 'components/common';
import { Header } from 'components/theme';

export default () => (
  <Layout>
    <Header />
    <Container>
      <h2>Legal information</h2>
      <p>This websites is owned and operated by</p>
      <p>Valentin - Andrei Despa</p>
      <p>Address: Luisenstr. 4, 55583 Bad Kreuznach, RP, Germany</p>
      <p>Email: valentin at vdespa.com</p>

      <h3>Additional information</h3>
      <p>
        This imprint is also valid for my YouTube channel which can be found at
        https://www.youtube.com/channel/UCUUl_HXJjU--iYjUkIgEcTw
      </p>
      <p>
        This imprint is also valid for my publications and articles which can be found at https://medium.com/@vdespa
      </p>
    </Container>
  </Layout>
);
